(function () {
  'use strict';
  
  document.addEventListener('DOMContentLoaded', () => {
    // `viewport` を変更
    const ua       = window.navigator.userAgent.toLowerCase();
    const viewport = document.querySelector('meta[name="viewport"]');
    const value    = {
      'default' : 'width=device-width, initial-scale=1',
      'tablet'  : 'width=1280'
    };

    viewport.setAttribute('name', 'viewport');

    if (
      (ua.indexOf('windows') !== -1 && ua.indexOf('touch') !== -1 && ua.indexOf('touch pc') == -1) || 
      (ua.indexOf('ipad') !== -1) || 
      (ua.indexOf('android') !== -1 && ua.indexOf('mobile') == -1) || 
      (ua.indexOf('firefox') !== -1 && ua.indexOf('tablet') !== -1) || 
      (ua.indexOf('kindle') !== -1) || 
      (ua.indexOf('silk') !== -1) || 
      (ua.indexOf('playbook') !== -1)
    ) {
      // タブレット
      viewport.setAttribute('content', value.tablet);
    }
  }, false);
})();